exports.components = {
  "component---src-components-templates-comic-template-index-tsx": () => import("./../../../src/components/templates/ComicTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-comic-template-index-tsx" */),
  "component---src-components-templates-solution-template-index-tsx": () => import("./../../../src/components/templates/SolutionTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-solution-template-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-comics-tsx": () => import("./../../../src/pages/comics.tsx" /* webpackChunkName: "component---src-pages-comics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merch-tsx": () => import("./../../../src/pages/merch.tsx" /* webpackChunkName: "component---src-pages-merch-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */)
}

