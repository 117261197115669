import React from 'react'
import type { GatsbyBrowser } from 'gatsby'

//Providers
import VariantProvider from './src/context/Variant/Variant.state'

// Style
import './src/styles/general.scss'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <VariantProvider>{element}</VariantProvider>
